<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Formland,%20Via%20Antica%20Consolare%20Campana,%20Qualiano,%20NA%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>
                        Ente accreditato dalla Regione Campania (codice 893/01/06) e certificato per la qualità aziendale UNI EN ISO 9001:2008 cod. EA 37. Nel corso degli anni, Formland ha sviluppato diversi progetti di successo nel campo della formazione e dell’orientamento al lavoro, in particolare attraverso l’attivazione di corsi professionali riconosciuti, promuovendo così lo sviluppo del territorio della provincia di Napoli e dell’intera regione Campania.
                    </p>
                    <p>
                        Formland, con accreditamento Forma.Temp (codice 0553.0935) – fondo per le politiche attive e passive verso candidati a missione di lavoro – attiva corsi di formazione di base, professionale, on the job e continua a titolo gratuito che non prevedono quote di alcun genere a carico dei partecipanti.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                    <p>
                        Inoltre, in convenzione con l’ente bilaterale Federterziario Apaie Napoli, la nostra scuola si è specializzata negli ultimi anni nella progettazione e organizzazione di corsi di formazione in materia di sicurezza e igiene sui luoghi di lavoro, in base al d.lgs. 81/2008, rivolti ad aziende e lavoratori di ogni settore produttivo.
                    </p>
                    <p>
                        Dal 2016 Formland è accreditata come centro qualificato per lo svolgimento di progetti didattici, educativi e formativi dell’Università telematica Pegaso, ateneo riconosciuto dal Miur, con decreto ministeriale del 20 aprile 2006 (GU n. 118 del 23/05/2006 – suppl. ordinario n. 125).
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Antica Consolare Campana, 133/135, 80035 Nola (NA)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+390818184792">(+39) 081-8184792</a></p>
                            <p>e-mail: <a href="mailto:formland@libero.it">formland@libero.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
